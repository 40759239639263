


















import { UserRoles } from '@homestyle/shared-data';
import { useStore } from 'vuex-simple';

import { MyStore } from '../store';

export default {
	metaInfo: {
		title: 'Page Not Found - Error 404',
	},
	data() {
		const store = useStore<MyStore>(this.$store);

		return {
			store,
		};
	},
	computed: {
		hasAccess() {
			const store: MyStore = this.store;
			const { role } = store.currentUser;

			return [UserRoles.Admin, UserRoles.Member].includes(role);
		},
	},
	mounted() {
		console.log('Access', this.hasAccess);

		setTimeout(() => {
			if (this.hasAccess) {
				this.$router.push('/');
			} else {
				this.$router.push('/login');
			}
		}, 5000);
	},
};
